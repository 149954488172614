@import "../../../../../sass/variables.sass";

.normal-form-bp {
  padding: 30px;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  
  &__content {
    box-sizing: border-box;
    width: 100%;
  }
  
  &__title-wr {
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  &__title {
    line-height: 1.2;
    font-size: 24px;
    font-weight: 500;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__typical-tasks {
    margin-bottom: 15px; 
    
    &--disabled {
      cursor: not-allowed;
      
      .typical-tasks-bp__items {
        pointer-events: none;
      }
    }
  }
  
  &__btns {
    display: flex;
  }
  
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-right: 25px;
    transition: all 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid transparent;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    background: #06a0eb;
    height: 40px;

    &:last-child {
      margin-right: 0;
    }
    
    &[disabled] {
      cursor: not-allowed;
      
      &:hover {
        background: #06a0eb;
      }
    }

    &--submit {
      width: 240px;
      
      &:hover {
        background: #02405e;
      }
    }

    &--cancel {
      border: 1px solid #e0e0e0;
      text-align: center;
      background: #e0e0e0;
      color: #212121;
      width: 120px;

      &:hover {
        border-color: #a8a8a8;
      }
    }

    &--show-preloader {
      .normal-form-bp__btn-preloader {
        opacity: 1;
        visibility: visible;
        z-index: 10;
      }
    }
  }

  &__btn-preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02405e;

    .preloader-local {
      top: 0;
      width: 18px;
      height: 18px;

      > div {
        width: 18px;
        height: 18px;
        border-width: 2px;
      }
    }
  }
  
  &__input {
    &--error {
      border-color: $red-main;
      
      &:hover, &:focus-within {
        border-color: $red-main;
      }
      
      .react-select__control {
        border-color: $red-main !important;
      }
    }
  }

  &__error-message {
    margin-bottom: 15px;
    color: $red-main;
    font-size: 12px;
  }
  
  &__other {
    margin: 25px 0;
    text-decoration: underline;
    transition: all 0.25s ease-in-out;
    
    &:hover {
      color: $hoverColorBlue;
      text-decoration: underline;
      cursor: pointer;
    }
    
    &__title{
     font-size: 16px;
     font-weight: 500;
     margin-bottom: 20px;
    }
  }
  &__other-full{
    margin: 25px 0;
  }

  &__close {
    background: none;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: $red-main;
    }
  }
}
