@import '../../../../sass/variables';

.multi-select-vanilla {
  position: relative;
  
  &:hover {
    .multi-select-vanilla__title-wr {
      border-color: $blue-main;
    }
  }

  &--without-search {
    .checkbox-people {
      position: relative;
      top: 0;
      height: auto;
    }
  }

  &--open {
    .multi-select-vanilla__title-ico {
      transform: rotate(180deg);
    }
    
    .multi-select-vanilla__wr {
      opacity: 1;
      visibility: visible;
    }

    .multi-select-vanilla__title-wr {
      border-color: $blue-main;
    }
  }
  
  &--show-preloader {
    .multi-select-vanilla__content-preloader {
      opacity: 1;
      visibility: visible;
    }
  }
  
  .checkbox-people {
    top: 0;
    left: 0;
    z-index: 100;
  }
  
  &__wr {
    transition: all 0.25s ease-in-out;
    will-change: opacity;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 100;
  }
  
  &__title-wr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 10px;
    cursor: pointer;
    height: 45px;
    padding: 15px;
    transition: border 0.25s ease-in-out;
  }
  
  &__title {
    font-family: Roboto,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #212121;
  }

  &__title-ico {
    transition: all 0.25s ease-in-out;
    position: relative;
    top: -1px;
    flex-shrink: 0;
    color: #212121;
  }

  &__content {
    position: relative;
    width: 100%;
    height: 304px;
  }

  &__content-preloader {
    backdrop-filter: blur(1px);
    opacity: 0;
    visibility: hidden;
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out, backdrop-filter 0.25s ease-in-out;
    will-change: opacity, visibility, backdrop-filter;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .preloader-local {
      top: 0;
      width: 24px;
      height: 24px;

      > div {
        border-color: $blue-main transparent transparent transparent;
        width: 24px;
        height: 24px;
        border-width: 2px;
      }
    }
  }
}
