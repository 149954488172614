@import '../../../../../../sass/variables';

.select-typical-task {
  position: relative;
  border-radius: 7px;

  &__title {
    position: absolute;
    top: -10px;
    left: 15px;
    font-size: 12px;
    background: $formBg;
    z-index: 10;
  }

  &__select {
    background: #fff;
    
    .multi-select-vanilla__title-wr {
      border-color: $border-gray;
    }
    
    .multi-select-vanilla__title {
      font-size: 14px;
    }

    .multi-select-vanilla__title-ico {
      top: 1px;
      transform: scale(0.8);
    }
    
    .checkbox-people {
      padding: 5px 0;  
    }
    
    .people {
      padding: 2px 5px;
    }
    
    .people__name {
      font-size: 14px;
    }
  }
}
