@import "../../../../sass/variables";

.business_process_table_header {
  width: 100%;
  padding: 0 95px 0 40px;

  .block_table_head {
    display: flex;
    justify-content: space-between;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #a8a8a8;
    align-items: center;
    height: 60px;

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .header_item_name {
    padding-left: 4px;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: $hoverColorBlue;
    }

    & > span {
      position: relative;
    }
  }

  .text_header {
    cursor: pointer;
  }

  .header_items_business_process {
    position: relative;
  }

  .header-business_process {
    padding-left: 4px;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: $hoverColorBlue;
    }

    & > span {
      position: relative;
    }

    &__id {
      width: 40px;
    }

    &__name {
      width: 200px;
    }

    &__treaty {
      width: 250px;
    }

    &__all_tasks {
      width: 80px;
    }

    &__duration {
      width: 115px;
    }

    &__author {
      width: 200px;
    }

    &__created_at {
      width: 100px;
    }
  }
}
