@import '../../../../sass/mixins';
@import '../../../../sass/variables';

.input-simple {
  position: relative;
  
  &--error {
    .input-simple__input {
      border-color: $red-main;
    }
  }

  &__title {
  
  }
  
  &__input {
    @include placeholder {
      font-size: 14px;
    }
    
    padding: 10px 10px 11px 10px;
    background: #fff;
    font-size: 14px;
    width: 100%;
    border: 1px solid $border-gray;
    border-radius: 4px;
    transition: border-color 0.25s ease-in-out;

    &:hover {
      border-color: $hoverColorBlue;
    }

    &:focus-within {
      border-color: $hoverColorBlue;
    }
  }

  &__error-messages {
    margin-top: 5px;
  }
  
  &__error-message {
    color: $red-main;
    font-size: 12px;
    margin-bottom: 10px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
}
