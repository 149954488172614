@import '../../sass/mixins';
@import '../../sass/variables';




.login_wrapper {
    // background: #F7F7F9
    // min-width: 1000px
    // min-height: 550px
    // @include flex(center, center)
 }    // @include size(100%, 100vh)
.login {
    // overflow: hidden
    // background: #FFFFFF
    // box-shadow: 0px 0px 15px rgba(32, 48, 90, 0.05)
    // border-radius: 20px
    // @include size(1000px, 550px)
    // @include flex
    // .image
    //     background-image: url('../../img/login.png')
    //     @include size(500px, 100%)
    // .login_form_wrapper
    //     flex-grow: 1
    //     flex-direction: column
    //     @include flex(center, center)
    // form
    //     width: 220px
    //     flex-direction: column
    //     @include flex(flex-start, center)
    .logo {
        background-image: url('../../img/login_logo.png');
        margin-bottom: 30px;
        @include size(100px, 59px); }
    .input_div {
        margin-bottom: 16px;
        width: 100%; }
    .sub_title {
        color: #454A63;
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 300;
        text-align: center; }
    a {
        text-decoration: none;
        margin-top: 30px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.3);
        transition: color .2s ease;
        &:hover {
            color: $blue-main; } }
    .have_account {
        margin-bottom: -25px;
        margin-top: 30px; } }



@media (max-height: 700px) {
    .loginForm {
      display: none; } }


@media (max-height: 600px) {
    .loginFormMobile {
      display: none; } }


@media (max-height: 850px) {
    .userManualMobile {
       margin-top: 15px; } }

@media (min-height: 851px) {
    .userManualMobile {
       margin-top: 35px; } }

