.documents-tab {
  &__blocks {
    
  }

  &__block {
    margin-bottom: 30px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  &__block-title {
    font-weight: 600;
  }

  &__sub-blocks {
    
  }

  &__sub-block {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__sub-title {
    font-weight: 500;
    font-size: 14px;
    margin-top: 2px;
  }

  &__block-items {
    margin-top: 15px;
  }

  &__block-item {
    display: flex;
    margin-bottom: 15px;
    font-size: 14px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__defaults {
    margin-top: 10px;
    font-size: 14px;
  }
}
