@import "../../../../../../sass/variables";

.business_process {
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  min-height: 84px;
  padding: 0 50px 0 30px;
  border-radius: 4px;
  background: #ffffff;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out;
  align-items: center;
  height: auto;

  &:hover {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  }

  &__actions {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    &:hover {
      .business_process__actions-btn {
        color: $hoverColorBlue;
      }
    }
  }

  &__actions-btn {
    color: #94959a;
    width: 25px;
    background: none;
    transition: all 0.25s ease-in-out;
    height: 100%;
  }

  &__avatar {
    background: #01b0e9;
    width: 40px;
    height: 40px;
    margin-right: 5px;

    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    border-radius: 50%;
    justify-content: center;
    color: #fafafa;
  }

  &__avatar-img-wr {
    width: 100%;
    height: 100%;
  }

  &__avatar-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__avatar-text {
  }

  &__bp {
  }

  &__business_process-items {
    margin: 10px 0;
  }

  &__business_process-item {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.business_process_id {
  min-width: 40px;
}

.business_process_name {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.business_process_treaty {
  width: 250px;
  text-wrap: nowrap;
  .business_process-treaty_name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.business_process_all-tasks {
  width: 80px;
}

.business_process_duration {
  width: 115px;
}

.business_process_executor {
  width: 200px;
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.business_process_author {
  width: 200px;
  display: flex;
  align-items: center;
}

.business_process_date {
  width: 100px;
}

.business_process_activity {
  width: 95px;
}
