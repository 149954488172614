@import "../../../../../sass/variables";

.task-typical {
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  min-height: 84px;
  padding: 0 30px;
  padding-right: 105px;
  border-radius: 4px;
  background: #ffffff;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out;
  align-items: center;

  &:hover {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  }
  
  &__actions {
    padding: 15px 4px;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    &:hover {
      .task-typical__actions-btn {
        color: $hoverColorBlue;
      }
    }
  }

  &__actions-btn {
    color: #94959A;
    width: 25px;
    background: none;
    transition: all 0.25s ease-in-out;
    height: 100%;
  }

  &__avatar {
    background: #01B0E9;
    width: 40px;
    height: 40px;
    margin-right: 5px;

    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    border-radius: 50%;
    justify-content: center;
    color: #fafafa;
  }

  &__avatar-img-wr {
    width: 100%;
    height: 100%;  
  }
  
  &__avatar-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__avatar-text {
    
  }

  &__bp {
    
  }

  &__bp-items {
    margin: 10px 0;
  }

  &__bp-item {
    margin-bottom: 5px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.id_task {
  min-width: 40px;
}

.name_task {
  min-width: 280px;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bp_task {
  width: 200px;
}

.executor_task {
  width: 200px;
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.author_task {
  width: 200px;
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.date_task {
  width: 200px;
}

.activity_task {
  width: 95px;
}
