.other-bp {
    max-width: 370px;
    width: 100%;
  
    &__title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    
    &__items {
      
    }
    
    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  
    &__item-left {
      min-width: 160px;
      
    }
  
    &__item-right {
      
    }
  
    &__item-title-wr {
      display: flex;
      align-items: center;
    }
  
    &__item-title-ico {
      flex-shrink: 0;
      margin-right: 10px;
      color: #7f7f7f;
    }
  
    &__item-title {
      flex-shrink: 1;
    }
  
    &__item-text {
      
    }
  
    &__item-select {
      min-width: 150px;
    }
  }
  