.comments-tab-objects-task-edit {
  position: relative;

  &--disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    backdrop-filter: grayscale(1);
  }
}
