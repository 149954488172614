.input-tab-typical-task {
  
  &__items {
    
  }

  &__item {
    margin-bottom: 20px; 
  }
}
