@import '../../../sass/variables';

.preloader-local {
  display: inline-block;
  position: relative;
  width: 22px;
  height: 22px;
  top: 1px;
  
  &--big {
    top: 0;
    width: 34px;
    height: 34px;

    > div {
      width: 34px !important;
      height: 34px !important;
      border-width: 4px !important;
      border-color: $blue-main transparent transparent transparent !important;
    }
  }
}

.preloader-local div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 22px;
  height: 22px;
  margin: 0;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: preloader-local 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.preloader-local div:nth-child(1) {
  animation-delay: -0.45s;
}

.preloader-local div:nth-child(2) {
  animation-delay: -0.3s;
}

.preloader-local div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes preloader-local {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
