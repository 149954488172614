@import "../../../../../../../sass/variables.sass";

.additional-fields-item {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  align-items: center;
  padding: 0 10px;
  position: relative;
  margin-bottom: 10px;
  background: #fff;
  min-height: 44px;

  &--disabled {
    padding-left: 47px;
    
    .additional-fields-item__type-drag-place {
      display: none;
    }
    
    .additional-fields-item__required {
      display: none;
    }
    
    .additional-fields-item__send-to-project {
      display: none;
    }
    
    .additional-fields-item__btn {
      display: none;
    }
    
    .additional-fields-item__title {
      text-decoration: none;
      cursor: default;
      pointer-events: none;

      &:hover {
        color: inherit;
      }
    }
    
    .additional-fields-item__status {
      display: block;
    }
  }
  
  &__type {
    width: 15%;
  }

  &__type-drag-place {
    cursor: grab;
    margin-right: 15px;
    
    > svg {
      transition: all 0.25s ease-in-out;
    }
    
    &:hover {
      color: $hoverColorBlue;
    }
  }

  &__title {
    width: 40%;
    padding-left: 3px;
    text-decoration: underline;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: $hoverColorBlue;
    }
  }

  &__required {
    width: 15%;
    margin-right: 10px;
  }

  &__checkbox-mui {
    &__check {
      padding: 0 !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }

    .MuiFormControlLabel-label {
      position: relative;
      top: 1px;
      font-size: 16px;
    }

    .MuiSvgIcon-root {
      transition: all 0.25s ease-in-out;
    }

    .Mui-checked {
      color: $hoverColorBlue;

      + .MuiFormControlLabel-label {
        color: $hoverColorBlue;
      }
    }

    &__label {
      margin-right: 0 !important;
      transition: all 0.25s ease-in-out;

      &:hover {
        color: $hoverColorBlue;

        .checkbox-mui__chk {
          color: $hoverColorBlue;
        }
      }
    }
  }

  &__send-to-project {
    width: 20%;
  }

  &__btn {
    background: none;
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;

    &:hover {
      &.additional-fields-item__btn--delete {
        color: $red-main;
      }
    }

    &--delete {
      > svg {
        transform: scale(0.9);
      }
    }
  }

  &__status {
    display: none;
    margin-left: auto;
  }
  
  &__status-item {
    border-radius: 50%;
    background: #99D7F5;
    transition: all 0.25s ease-in-out;
    width: 28px;
    height: 28px;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
    
    &:hover {
      background: $hoverColorBlue;
    }
    
    &--send {
      
    }
  }

  &__status-ico {
    color: #fff;
    position: relative;
    top: 9px;
    left: 9px;
  }
}
