@import "../../../../../../../sass/variables.sass";

.extra-form-stage-add {
  height: 100%;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  background: $formBg;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  will-change: opacity, visibility;
  padding: 75px 30px 75px 30px;
  line-height: 1.45;

  @media #{$desktop1600W} {
    padding: 75px 170px 75px 30px;
  }

  &--open {
    opacity: 1;
    visibility: visible;
  }

  &__stage-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__stage-text {
    margin-left: 20px;
    font-size: 16px;
  }

  &__stage-text-instruction {
    margin-top: 20px;
    font-size: 16px;
    &:hover {
      text-decoration: underline;
    }
  }

  &__btns {
    display: flex;
    margin-top: 25px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-right: 25px;
    transition: all 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid transparent;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    background: #06a0eb;
    height: 40px;

    &:last-child {
      margin-right: 0;
    }

    &--submit {
      width: 240px;

      &:hover {
        background: #02405e;
      }
    }

    &--cancel {
      border: 1px solid #e0e0e0;
      text-align: center;
      background: #e0e0e0;
      color: #212121;
      width: 120px;

      &:hover {
        border-color: #a8a8a8;
      }
    }

    &--show-preloader {
      .normal-form-bp__btn-preloader {
        opacity: 1;
        visibility: visible;
        z-index: 10;
      }
    }
  }

  &__btn-preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02405e;

    .preloader-local {
      top: 0;
      width: 18px;
      height: 18px;

      > div {
        width: 18px;
        height: 18px;
        border-width: 2px;
      }
    }
  }
}

.add_stage_form {
  width: 80%;
  height: 50px;
  background-color: #fff;
  border-radius: 5px;
  padding: 12px;
  color: #212121;
  border: 1px solid rgb(153, 153, 153);
  resize: none;
  margin: 20px 0;

  &:hover {
    border-color: $hoverColorBlue;
  }

  &:focus-within {
    border-color: $hoverColorBlue;
  }
}
