@import "../../../../sass/variables";

.tasks_table_header_objects {
  height: 60px;
  padding: 0 65px 0 30px;
  display: flex;
  width: calc(100vw - 309px);

  @media (max-width: 1600px) {
    width: calc(100vw - 185px);
    padding-right: 0;
    margin-right: 0;
  }

  @media #{$desktop1900W} {
    width: calc(100vw - 326px);
  }

  .block_table_head {
    display: flex;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #a8a8a8;
    align-items: center;
    height: 60px;
    flex-grow: 1;
    width: 33.3%;

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .header-item-objects {
    padding-left: 4px;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: $hoverColorBlue;
    }

    & > span {
      position: relative;
    }

    &__id {
      width: 65px;
      padding-left: 20px;
    }

    &__days {
      width: 60px;
    }

    &__name {
      width: 155px;
    }

    &__agreement_number {
      width: 145px;
      flex-shrink: 0;
    }

    &__project_manager_name {
      width: 165px;
      flex-shrink: 0;
    }

    &__progress {
      width: 70px;
      pointer-events: none;
      flex-shrink: 0;
    }

    &__in_work {
      width: 100px;
      pointer-events: none;
      flex-shrink: 0;
    }

    &__completed_tasks_count {
      width: 85px;
      flex-shrink: 0;

      @media #{$desktop1600W} {
        width: 135px;
      }
    }

    &__overdue_tasks_count {
      width: 90px;
      flex-shrink: 0;
    }

    &__status {
      width: 135px;
      pointer-events: none;
      flex-shrink: 0;
    }
  }

  .header_items {
    position: relative;
  }

  .text_header {
    cursor: pointer;
  }
}
