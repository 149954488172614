@import "../../../../sass/variables";

.main-business_process {
  height: 100%;
  padding: 30px 0;

  &__title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px;
    padding: 0 30px;
  }

  &__filters {
    padding: 0 30px;
  }

  &__content {
    background: #f4f4f4;
    height: 100%;
  }

  &__table {
    position: relative;
    margin-top: 5px;
  }

  &__table-items {
    padding: 0 10px;
    padding-bottom: 10px;
    overflow-y: scroll;
    height: calc(100vh - 286px);

    @media #{$desktop1600W} {
      padding-bottom: 0;
    }
  }

  &__preloader-wr {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.25s ease-in-out;
    will-change: opacity, visibility;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: calc(100vh - 238px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    backdrop-filter: blur(1px);
    background: rgba(255, 255, 255, 0.9);

    &--show {
      opacity: 1;
      visibility: visible;
    }

    .preloader-local {
      width: 42px;
      height: 42px;

      > div {
        border-color: $blue-main transparent transparent transparent;
        width: 42px;
        height: 42px;
        border-width: 3px;
      }
    }
  }

  &__default {
    font-size: 16px;
    margin-top: 30px;
  }

  &__none-list {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 30px;
  }

  &__add_bp {
    color: rgb(1, 176, 233);
    cursor: pointer;
    padding-left: 5px;
    &:hover {
      text-decoration: underline;
    }
  }
}
