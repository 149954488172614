@import "../../../../sass/mixins";
@import "../../../../sass/variables";

.form-treaties {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  will-change: opacity, visibility;
  opacity: 0;
  visibility: hidden;

  &--open {
    opacity: 1;
    visibility: visible;

    .form-treaties__content {
      transform: translateX(calc(100% - 660px));

      @media #{$desktop1600W} {
        transform: translateX(calc(100% - 710px));
      }
      @media screen and (min-width: 1800px) {
        transform: translateX(calc(100% - 810px));
      }
    }
  }

  &--open-full {
    .form-treaties__content {
      transform: translateX(0);
    }
  }

  &--show-preloader {
    .form-treaties__preloader {
      opacity: 1;
      visibility: visible;
    }
  }

  &__content {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $formBg;
    transition: transform 0.25s ease-in-out;
    will-change: translateX;
    transform: translateX(100%);
  }

  &__left {
    position: relative;
    flex-shrink: 0;
    border-left: 10px solid $blue-lighter;
    border-right: 10px solid $blue-lighter;
    height: calc(100vh - 63px);
    overflow-y: scroll;
    padding-bottom: 0;
    margin-bottom: 0;
    max-width: 810px;
    width: 660px;

    @media #{$desktop1600W} {
      width: 710px;
    }
    @media screen and (min-width: 1800px) {
      width: 810px;
    }
    @media screen and (max-width: 1300px) {
      width: 560px;
    }
  }

  &__right {
    position: relative;
    width: 100%;
    height: calc(100vh - 63px);
  }

  &__preloader {
    @include backgroundCustom;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    will-change: opacity, visibility;
    opacity: 0;
    visibility: hidden;
    z-index: 100;
  }
}
