@import '../../../../../sass/variables';

.task-menu {
  overflow-x: auto;
  &__title-wr {
    &--show-preloader {
      .task-menu__preloader {
        opacity: 1;
        visibility: visible;
        z-index: 10;
        cursor: default;
      }
    }
  }
  
  &__title {
    
  }

  &__preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;

    .preloader-local {
      width: 18px;
      height: 18px;

      > div {
        border-color: $blue-main transparent transparent transparent;
        width: 18px;
        height: 18px;
        border-width: 2px;
      }
    }
  }
}
