@import '../../../../../sass/mixins';
@import '../../../../../sass/variables';

.date-picker-true {
  &--input {
    .MuiInput-formControl {
      padding: 0 10px 0 15px;
      background: #fff;
      font-size: 14px;
      width: 100%;
      border: 1px solid $border-gray;
      border-radius: 7px;
      transition: border-color 0.25s ease-in-out;

      &:before, &:after {
        border: none !important;
      }
      
      &:hover {
        border-color: $hoverColorBlue;
      }

      &:focus-within {
        border-color: $hoverColorBlue;
      }
    }
    
    .MuiInput-input {
      @include placeholder {
        font-size: 14px !important;
      }
      
      padding: 14px 0 12px 0;
    }
    
    .Mui-error {
      line-height: 1.1;
    }
  }
  
  &--error {
    .MuiInput-formControl {
      border-color: $red-main !important;
    }
  }
  
  &__error-messages {
    margin-top: 5px;
    line-height: 1.1;
  }

  &__error-message {
    color: $red-main;
    font-size: 12px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
