@import "../../../../sass/variables";

.main-objects {
  height: 100%;
  padding: 30px 0;
  // overflow-y: scroll;

  &__title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px;
    padding: 0 30px;
  }

  &__filters {
    padding: 0 30px;
  }

  &__content {
    background: #f4f4f4;
    height: 100%;
  }

  &__table {
    position: relative;
    margin-top: 5px;
  }

  &__table-items {
    padding: 0 10px;
    padding-bottom: 10px;
    overflow-y: scroll;
    height: calc(100vh - 190px);

    @media #{$desktop1600W} {
      padding-bottom: 100px;
    }
  }

  &__preloader-wr {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.25s ease-in-out;
    will-change: opacity, visibility;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    backdrop-filter: blur(1px);
    background: rgba(255, 255, 255, 0.9);

    &--show {
      opacity: 1;
      visibility: visible;
    }

    .preloader-local {
      width: 42px;
      height: 42px;

      > div {
        border-color: $blue-main transparent transparent transparent;
        width: 42px;
        height: 42px;
        border-width: 3px;
      }
    }
  }

  &__default {
    font-size: 16px;
    margin-top: 30px;
  }
}

.info_none_objects {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 30px;
}

.add_obj_link {
  color: rgb(1, 176, 233);
  cursor: pointer;
  padding-left: 5px;
  &:hover {
    text-decoration: underline;
  }
}
