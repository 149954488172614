@import "../../../../sass/variables";

.typical_treaties_table_header {
  width: 100%;
  padding: 0 55px 0 35px;

  .block_table_head {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 35px;
    //display: flex;
    justify-content: space-between;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #a8a8a8;
    align-items: center;
    height: 60px;

    svg {
      width: 10px;
      height: 10px;
    }
  }

  .header_items_typical-treaties {
    position: relative;
  }

  .text_header {
    cursor: pointer;
  }

  .header-typical-treaties {
    padding-left: 4px;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: $hoverColorBlue;
    }

    & > span {
      position: relative;
    }

    &__id {
      min-width: 40px;
    }

    &__name {
      grid-column: 2/4;
      min-width: 215px;
    }

    &__all_stage {
      min-width: 50px;
    }

    &__bp_count {
      min-width: 50px;
    }

    &__all_tasks {
      min-width: 50px;
    }

    &__duration {
      min-width: 30px;
    }

    &__author {
      min-width: 110px;
    }

    &__created_at {
      width: 100px;
    }

    &__status {
      min-width: 95px;
    }
  }
}

@media (max-width: 1700px) {
  .typical_treaties_table_header {
    padding: 0 50px 0 35px;
  }
}

@media (max-width: 1400px) {
  .typical_treaties_table_header {
    padding: 0 40px 0 35px;
    .header-typical-treaties {
      padding-left: 4px;
      transition: all 0.25s ease-in-out;

      &:hover {
        color: $hoverColorBlue;
      }

      & > span {
        position: relative;
      }

      &__name {
        grid-column: 2/4;
        min-width: 195px;
      }

      &__task_count {
        min-width: 80px;
      }

      &__bp_count {
        min-width: 80px;
      }

      &__duration {
        min-width: 80px;
      }

      &__created_at {
        width: 80px;
      }
    }
  }
}

@media (max-width: 1300px) {
  .typical_treaties_table_header {
    padding: 0 40px 0 35px;
    .header-typical-treaties {
      padding-left: 4px;
      transition: all 0.25s ease-in-out;

      &:hover {
        color: $hoverColorBlue;
      }

      & > span {
        position: relative;
      }

      &__name {
        grid-column: 2/4;
        min-width: 185px;
      }

      &__task_count {
        min-width: 50px;
      }

      &__bp_count {
        min-width: 60px;
      }

      &__duration {
        min-width: 60px;
      }

      &__author {
        min-width: 90px;
      }

      &__created_at {
        width: 80px;
      }
    }
  }
}
