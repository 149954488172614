@import "../../../../../sass/variables.sass";

.container_add_typical_from {
  padding: 30px;
}

.add_normal_form_options {
  box-sizing: border-box;
  width: 100%;
}

.title_options {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.name-typical-task {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 12px;
  color: #212121;
  border: 1px solid rgb(153, 153, 153);
  resize: none;
  margin-bottom: 20px;
  height: 40px;

  &:hover {
    border-color: $hoverColorBlue;
  }

  &:focus-within {
    border-color: $hoverColorBlue;
  }
}

.typical-task-description {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  padding: 12px;
  color: #212121;
  border: 1px solid rgb(153, 153, 153);
  resize: none;
  margin-bottom: 20px;
  height: 60px;

  &:hover {
    border-color: $hoverColorBlue;
  }

  &:focus-within {
    border-color: $hoverColorBlue;
  }
}

.time_task {
  width: 100%;
  display: flex;
}

.executor_add_task {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}

.name_time_task {
  display: flex;
  align-items: center;
  height: 40px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.name_executor_task {
  display: flex;
  align-items: center;
  height: 40px;
  width: 30%;
  margin-right: 20px;
  margin-bottom: 20px;
}

.img_task_time {
  width: 20px;
  height: 22px;
  margin-right: 10px;
}

.input_time_day {
  width: 13%;
  height: 40px;
  overflow: hidden;
  border: 1px solid rgb(153, 153, 153);
  border-radius: 5px;
  padding: 8px;
  margin-right: 30px;

  &:hover {
    border-color: $hoverColorBlue;
  }

  &:focus-within {
    border-color: $hoverColorBlue;
  }
}

.toogle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.select_executor {
  width: 70%;
}

.checkbox_typical_task {
  margin-bottom: 30px;
}

.additional_fields_title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.add_field {
  width: fit-content;
  margin-left: 10px;
  margin-bottom: 30px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

  &-option {
    color: $black-darkest;
    width: fit-content;
    margin-bottom: 30px;
    cursor: pointer;
    text-decoration: underline;
    transition: all 0.25s ease-in-out;
    
    &:hover {
      color: $hoverColorBlue;
    }
  }
}

.normal-form {
  overflow: hidden;
  
  &__title-wr {
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  &__title {
    line-height: 1.2;
    font-size: 24px;
    font-weight: 500;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &__additional-fields {
    margin-bottom: 30px;
  }

  &__additional-fields-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  &__additional-fields-content {
    
  }
  
  &__btns {
    display: flex;
  }
  
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-right: 25px;
    transition: all 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid transparent;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    background: #06a0eb;
    height: 40px;

    &:last-child {
      margin-right: 0;
    }

    &--submit {
      width: 240px;
      
      &:hover {
        background: #02405e;
      }
    }

    &--cancel {
      border: 1px solid #e0e0e0;
      text-align: center;
      background: #e0e0e0;
      color: #212121;
      width: 120px;

      &:hover {
        border-color: #a8a8a8;
      }
    }

    &--show-preloader {
      .normal-form__btn-preloader {
        opacity: 1;
        visibility: visible;
        z-index: 10;
      }
    }
  }

  &__btn-preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02405e;

    .preloader-local {
      top: 0;
      width: 18px;
      height: 18px;

      > div {
        width: 18px;
        height: 18px;
        border-width: 2px;
      }
    }
  }
  
  &__input {
    &--error {
      border-color: $red-main;
      
      &:hover, &:focus-within {
        border-color: $red-main;
      }
      
      .react-select__control {
        border-color: $red-main !important;
      }
    }
  }
  
  &__additional-fileds {
    margin-top: 20px;
  }

  &__error-message {
    margin-bottom: 15px;
    color: $red-main;
    font-size: 12px;
  }
  
  &__other {
    margin-bottom: 25px;
  }

  &__close {
    background: none;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: $red-main;
    }
  }
}

.checklist {
  .progressBar {
    display: flex;
    height: 24px;

    .digit_progress {
      font-size: 16px;
      margin-right: 8px;
      line-height: 24px;
      cursor: default;
    }

    .bar {
      position: relative;
      width: 100%;
      margin-top: 5px;

      .all_time {
        margin: inherit;
        position: absolute;
        width: 100%;
        height: 4px;
        background: rgba(27, 170, 240, 0.1);
        border-radius: 4px;
      }
      .completed {
        transition: 0.5s ease;
        margin: inherit;
        position: absolute;
        height: 4px;
        background: $blue-main;
        border-radius: 4px;
      }
    }
  }
  .header {
    color: $blue-main;
    display: flex;
    height: 40px;
    width: 100px;
    padding: 8px 12px;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 8px;
    cursor: pointer;

    .expandedCheckListItems {
      background-image: url("../../../../../img/up.svg");
      cursor: pointer;
    }

    .collapsedCheckListItems {
      background-image: url("../../../../../img/down.svg");
    }
    div:first-child {
      padding-top: 4px;
    }

    div:last-child {
      width: 24px;
      height: 24px;
      background-position: 2px 1px;
      background-size: 20px;
      background-repeat: no-repeat;
    }
  }
  #checkItems {
    .checkItem {
      display: flex;
      line-height: 18px;
      justify-content: space-between;
      margin-bottom: 10px;

      .input_div {
        padding: 0 12px;
      }
      &:hover {
        .deleteButton {
          visibility: visible;
        }
      }
      .deleteButtonBox {
        display: flex;

        .deleteButton {
          align-self: center;
        }
      }
      .checkItemContent {
        display: flex;
        line-height: 32px;

        .checkbox_item {
          margin: 0;

          .checkbox_icon {
            // @include size(30px, 30px);
            width: 25px;
            height: 25px;
            cursor: pointer;
            margin: 0;
            background-image: url("../../../.././../img/checkbox empty.svg");
            background-repeat: no-repeat;
            background-size: 30px;
            margin-right: 10px;

            &:hover {
              background-image: url("../../../../../img/checkbox ready.svg");
            }
            &.checked {
              background-image: url("../../../../../img/checked1.svg");
            }
          }
        }
        .orderNumber {
          margin-left: 8px;
          margin-right: 3px;
          cursor: default;

          &.asInput {
            line-height: 40px;
          }
        }
        .nameItem {
          cursor: pointer;
        }
      }
      .deleteButton {
        visibility: hidden;
        width: 16px;
        height: 16px;
        color: black;
        background-image: url("../../../../../img/x.svg");
        background-position: -2px -2px;
        background-size: 20px;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
    #addCheckItem {
      height: 40px;
      display: flex;
      width: fit-content;
      cursor: pointer;

      .addNewRowIcon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        background-image: url("../../../../../img/plus.svg");
      }
      .addNewRowText {
        font-size: 14px;
        line-height: 25px;
        color: $blue-main;
      }
    }
  }
}
