@import '../../../../../../sass/variables';

.description-tab-objects-task-edit {
  font-size: 14px;
  position: relative;
  
  &--disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    backdrop-filter: grayscale(1);
  }
  
  &__info {
    &--margin {
      margin-top: 25px;
    }
    
    &--style {
      font-style: italic;
      color: #7F7F7F;

      .description-tab-objects-task-edit__info-left {
        min-width: 170px;
        width: 170px;
      }
    }
  }

  &__info-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  &__info-row {
    display: flex;
  }

  &__info-left {
    margin-right: 25px;
    min-width: 120px;
  }

  &__info-right {
    
  }

  &__blocks {
    margin-top: 20px;
  }

  &__block {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__block-title {
    font-size: 16px;
    font-weight: 500;
  }

  &__block-items {
    margin-top: 10px;
  }

  &__block-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__block-item-left {
    flex-shrink: 0;
    min-width: 150px;
  }

  &__block-item-right {
    flex-grow: 1;
  }

  &__block-item-title {

  }

  &__block-item-select {

  }

  &__block-item-chk-wr {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  &__block-item-chk {
    .MuiIconButton-label {
      transform: scale(0.9);
    }

    .MuiFormControlLabel-label {
      font-size: 14px !important;
    }
  }

  &__block-item-info {
    margin-left: 20px;
    flex-shrink: 0;
    
    .tooltip-question__content {
      top: -1px;
    }
  }

  &__block-item-select-wr {
    display: flex;
    align-items: center;
  }

  &__block-item-select {
    flex-grow: 1;

    .multi-select-vanilla__title-wr {
      height: 40px;
    }

    .multi-select-vanilla__title {
      font-size: 14px;
    }

    .multi-select-vanilla__title-ico {
      transform: scale(0.8);
    }

    .multi-select__search-input {
      font-size: 14px;
    }
  }
}
