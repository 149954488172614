.typical-tasks-bp {
  height: 100%;

  &__items-header {
    display: grid;
    grid-template-columns: 50px 1fr 130px 50px;
    background-color: #1BAAF0;
    border-radius: 5px;
    color: #fff;
    height: 40px;
    padding: 0 10px;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
  }

  &__items-header-col {
    
  }
  
  &__items {
    margin-top: 10px;
    height: 100%;
    width: 100%;
  }

  &__default-message {
    margin-top: 20px; 
  }
}
