@import "../../../../sass/variables.sass";

.filter_typical-treaties {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  &__left {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  // &__right {
  // }

  &__filters-selectors {
    display: flex;
    align-items: center;
    margin-right: 40px;
    flex-wrap: nowrap;
  }

  &__filters-selector-item {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 15px;

    @media #{$desktop1600W} {
      margin-bottom: 0;
      margin-right: 40px;
    }

    &:last-child {
      margin-right: 0;

      .filter-business-process__filters-selector-item-title {
        margin-right: 25px;

        @media #{$desktop1600W} {
          margin-right: 15px;
        }
      }
    }
  }

  &__filters-selector-item-title {
    margin-right: 15px;
  }

  &__filters-selector-item-element {
    width: 150px;

    @media #{$desktop1600W} {
      width: 330px;
    }

    &--width {
      width: 200px;

      .multi-select-vanilla__wr {
        width: 100% !important;
      }
    }

    .multi-select-vanilla__wr {
      width: 330px;

      @media #{$desktop1600W} {
        width: 100%;
      }
    }
  }

  // &__filters-toggle {

  // }

  &__add-btn {
    color: #fff;
    padding: 16px 15px;
    background: $blue-main;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid transparent;
    font-family: Roboto, sans-serif;
    font-style: normal;
    line-height: 16px;
    text-align: center;
    transition: all 0.25s ease-in-out;
    white-space: nowrap;

    &:hover {
      background: $blue-lighter;
    }
  }
}
