@import "../../../../../sass/variables.sass";

.additional_title {
  display: flex;
  background-color: $blue-main;
  font-weight: 400;
  font-size: 13px;
  height: 40px;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  color: #fff;
  border-radius: 5px;
}

.field_type {
  width: 15%;
  margin-left: 35px;
}

.name_field {
  width: 40%;
}

.necessarily_field {
  width: 15%;
  margin-right: 10px;
}

.transfer_field {
  width: 20%;
}

.additional-fields {
  
  &--edit-form {
    .necessarily_field {
      display: none;
    }
    
    .transfer_field {
      display: none;
    }
  }
  
  &__no-items {
    font-size: 14px;
    margin-top: 20px;
    
    &--big {
      line-height: 18px;
      font-size: 14px;
    }
  }

  &__add-field {
    text-decoration: underline;
    font-size: 14px;
    transition: all 0.25s ease-in-out;
    margin-top: 5px;
    background: none;
    
    &:hover {
      color: $hoverColorBlue;
    }
  }
}
