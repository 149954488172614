.priority-custom {
  display: flex;
  align-items: center;
  
  &__left {
    margin-right: 10px;
  }
  
  &__right {
    
  }
}
