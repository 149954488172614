@import "../../../../sass/variables";


.typical-treaties-item-component_container {
  display: flex;
  //width: 98%;
  padding-left: 3%;
}

.typical-treaties-item-component {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  //display: flex;
  margin-bottom: 12px;
  justify-content: space-between;
  min-height: 84px;
  padding: 0 15px 0 25px;
  border-radius: 4px;
  background: #ffffff;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out;
  align-items: center;
  height: auto;
  width: 100%;

  &:hover {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  }

  &__avatar {
    background: #01b0e9;
    width: 40px;
    height: 40px;
    margin-right: 5px;

    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    border-radius: 50%;
    justify-content: center;
    color: #fafafa;
  }

  &__avatar-img-wr {
    width: 100%;
    height: 100%;
  }

  &__avatar-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__actions {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    &:hover {
      .business_process__actions-btn {
        color: $hoverColorBlue;
      }
    }
  }

  &__actions-btn {
    color: #94959a;
    width: 25px;
    background: none;
    transition: all 0.25s ease-in-out;
    height: 100%;
  }
}

.typical-treaties-item-component_id {
  min-width: 40px;
}

.typical-treaties-item-component_name {
  grid-column: 2/4;
  min-width: 200px;
  margin-left: -15%;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.typical-treaties-item-component_all_stage {
  min-width: 50px;
  margin-left: -25%;
}

.typical-treaties-item-component_all_business_process {
  min-width: 50px;
  margin-left: -25%;
}

.typical-treaties-item-component_all_tasks {
  min-width: 50px;
  margin-left: -20%;
}

.typical-treaties-item-component_duration {
  min-width: 30px;
  margin-left: -15%;
}

.typical-treaties-item-component_author {
  display: flex;
  margin-left: -10%;
  min-width: 130px;
  align-items: center;
}

.typical-treaties-item-component_created_at {
  min-width: 100px;
  margin-left: -5%;
}

.typical-treaties-item-component_status {
  min-width: 95px;
  margin-left: -5%;
}
