@import '../../../../sass/mixins';
@import '../../../../sass/variables';

.form-editing-object {
  //@include backgroundCustom;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out, transform 0.25s ease-in-out;
  will-change: opacity, visibility, transform;
  opacity: 0;
  visibility: hidden;
  
  &--open {
    opacity: 1;
    visibility: visible;

    .form-editing-object__content-wr {
      transform: translateX(calc(100% - 1200px));
      opacity: 1;
      visibility: visible;
    }
  }

  &--show-preloader {
    .form-editing-object__preloader {
      opacity: 1;
      visibility: visible;
    }

    .form-editing-object__content {
      overflow-y: hidden;
    }
  }
  
  &--add-form {
    .form-editing-object__tabs-header {
      display: none;
    }
  }

  &__title-wr {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    justify-content: space-between;
  }

  &__title {
    margin-right: 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  &__title-stage {
    margin-top: 15px;
  }

  &__content-wr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    will-change: transform, opacity, visibility;
    transform: translateX(100%);
    opacity: 0;
    visibility: hidden;
  }
  
  &__content {
    position: relative;
    width: 600px;
    background: $formBg;
    transform: translateX(100%);
    padding: 30px;
    border-left: 10px solid $blue-lighter;
    border-right: 10px solid $blue-lighter;
    height: calc(100vh - 63px);
    overflow-y: scroll;
  }

  &__preloader {
    @include backgroundCustom;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    will-change: opacity, visibility;
    opacity: 0;
    visibility: hidden;
    z-index: 2000;
  }

  &__close {
    background: none;
    transition: all 0.25s ease-in-out;
    
    &:hover {
      color: $red-main;
    }
  }

  &__tabs {
    margin-top: 20px;
    margin-bottom: 30px;

    .MuiPaper-root {
      background-color: transparent;
      color: $black-darkest;
      box-shadow: none;
      position: relative;
      margin-bottom: 30px;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: $black-lighter;
      }
    }

    .MuiTabs-flexContainer {

    }

    .MuiButtonBase-root {
      flex-grow: 1;
      min-width: auto;
      text-transform: none;
      font-weight: 400;
      font-size: 14px;
      opacity: 1;
      transition: color 0.25s ease-in-out;

      &:hover {
        color: $hoverColorBlue;
      }
    }

    .Mui-selected {
      color: $hoverColorBlue;
    }

    .MuiTabs-indicator {
      z-index: 10;
      background-color: $hoverColorBlue !important;
    }
    
    .Mui-disabled {
      cursor: not-allowed !important;
      
      &.MuiButtonBase-root {
        &:hover {
          color: inherit !important;
        }  
      }
    }
  }

  &__tab-content {
    
  }
  
  &__btns {
    display: flex;
    margin-top: 30px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-right: 25px;
    transition: all 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid transparent;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    background: #06a0eb;
    height: 40px;

    &:last-child {
      margin-right: 0;
    }
    
    &[disabled] {
      filter: grayscale(1);
      pointer-events: none;
      
      &:hover {
        filter: grayscale(1);
      }
    }

    &--submit {
      width: 200px;

      &:hover {
        background: #02405e;
      }
    }

    &--cancel {
      border: 1px solid #e0e0e0;
      text-align: center;
      background: #e0e0e0;
      color: #212121;
      width: 120px;

      &:hover {
        border-color: #a8a8a8;
      }
    }

    &--show-preloader {
      .form-editing-object__btn-preloader {
        opacity: 1;
        visibility: visible;
        z-index: 10;
      }
    }
  }

  &__btn-preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02405e;

    .preloader-local {
      top: 0;
      width: 18px;
      height: 18px;

      > div {
        width: 18px;
        height: 18px;
        border-width: 2px;
      }
    }
  }
}
