@import "../../../../../sass/variables";

.task-style {
  display: flex;
  margin: 0 12px;
  margin-bottom: 12px;
  justify-content: space-between;
  min-height: 84px;
  padding: 15px 0 15px 20px;
  border-radius: 4px;
  border-image: linear-gradient(to right, #ffffff, #e0e0e0, #e0e0e0, #ffffff)
    45% 0%;
  background: #ffffff;
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  }

  &__actions {
    padding: 15px 4px;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    &:hover {
      .task-style__actions-btn {
        color: $hoverColorBlue;
      }
    }
  }

  &__actions-btn {
    color: #94959a;
    width: 25px;
    background: none;
    transition: all 0.25s ease-in-out;
    height: 100%;
  }
}
.left_box {
  position: relative;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.right_flex {
  position: relative;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  padding-right: 40px;
}

.name_section {
  margin-right: 12px;
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deadline_task {
  width: 93px;
  display: flex;
  align-items: center;
}

.executor_for_task {
  width: 190px;
  display: flex;
  align-items: center;
  position: relative;

  &__name {
    padding-left: 10px;
  }

  &__preloader {
    position: absolute;
    background: #fff;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    opacity: 0;
    visibility: hidden;
    
    &--show {
      opacity: 1;
      visibility: visible;
    }
  
    &--hidden {
      position: absolute;
      visibility: hidden;
    }
    
    > div {
      opacity: $preloaderSlowReqOpacity;
  
      > div {
        border-color: $blue-main transparent transparent transparent;
      }
    }
  }

}

@media (max-width: 1550px) {
  .executor_for_task {
    width: 150px;
    display: flex;
    align-items: center;
    word-break:break-all;
  }
}