@import '../../../../../sass/mixins';
@import '../../../../../sass/variables';

.textarea-objects {
  position: relative;
  
  &--error {
    .textarea-objects__input {
      border-color: $red-main;
    }
  }

  &__title {
   
  }
  
  &__input {
    @include placeholder {
      font-size: 14px;
      opacity: 1;
    }
    
    min-height: 80px;
    padding: 14px 10px 12px 15px;
    background: #fff;
    font-size: 14px;
    width: 100%;
    border: 1px solid $border-gray;
    border-radius: 7px;
    transition: border-color 0.25s ease-in-out;

    &:hover {
      border-color: $hoverColorBlue;
    }

    &:focus-within {
      border-color: $hoverColorBlue;
    }
  }

  &__error-message {
    margin-top: 5px;
    color: $red-main;
    font-size: 12px;
  }
}
