@import "../../../../../../sass/variables.sass";

.MuiAccordion-root {
  width: 98%;
  margin: 10px 10px 10px 10px;
  box-shadow: none;
  border: none;
  border-radius: 4px;
  transition: box-shadow 0.25s ease-in-out;
  border: none;

  &:hover {
    box-shadow: 0px 4px 5px #00000033 !important;
  }
}

.object {
  &__actions {
    padding: 10px 15px;
    position: absolute;
    top: 10px;
    right: -15px;
    transform: translateY(-50%);
  }

  &__actions-btn {
    color: #94959a;
    width: 25px;
    background: none;
    transition: all 0.25s ease-in-out;
    height: 100%;
  }

  &__actions-btn:hover {
    color: $hoverColorBlue;
  }
}

.object_accordion {
  height: auto;
  transition: box-shadow 0.25s ease-in-out !important;
}

.object-AccordionSym {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 0px 19px 0px 19px;
  cursor: auto;
  word-wrap: break-word;
}

.id_object {
  display: block;
  color: #292a34;
  margin-right: 10px;
  font-size: 14px;
  width: 35px;
  color: #a8a8a8;
  padding-left: 5px;
}

.header_accordion {
  display: flex;
  overflow: hidden;
  width: calc(100vw - 410px);

  @media (max-width: 1600px) {
    width: calc(100vw - 220px);
  }
}

.right_plate {
  display: flex;
}

.left_plate {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
}

.day_section {
  width: fit-content;
  min-width: 60px;
}

.accord_vector {
  width: 15px;
  min-width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  transition: transform 0.3s ease;

  &.open {
    transform: rotate(90deg);
  }
}

.name_obj {
  min-width: 155px;
  padding-right: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-clamp: 1;
  display: -webkit-box;
  display: block;
  word-wrap: break-word;
  box-orient: vertical;
}
.treaty_obj {
  max-width: 155px;
  line-height: 20px;
  width: 145px;
  flex-shrink: 0;
}

.responsible_task {
  min-width: 175px;
  max-width: 175px;
  display: flex;
  width: 175px;
  flex-shrink: 0;
}

.response_name {
  height: fit-content;
  align-items: center;
  line-height: 20px;
}

.responsible {
  &.avatar {
    background: #01b0e9;
    width: 40px;
    height: 40px;
    margin-right: 5px;

    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
    border-radius: 50%;
    justify-content: center;
    color: #fafafa;
  }

  &.avatar-img-wr {
    width: 100%;
    height: 100%;
  }

  &.avatar-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.tasks_in_work {
  width: 100px;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;

  &__count {
    padding-left: 10px;
  }
}

.status_object {
  width: 140px;
  flex-shrink: 0;
}

.count_completed {
  padding-left: 5px;
}

.completed_tasks_obj {
  width: 85px;
  display: flex;
  flex-shrink: 0;

  @media #{$desktop1600W} {
    width: 135px;
  }
  
  > svg {
    margin-top: 3px;
  }
}

.overdue_task_obj {
  width: 90px;
  display: flex;
  flex-shrink: 0;

  > svg {
    margin-top: 2px;
  }
}

.exec_obj {
  width: 240px;
  display: flex;
  margin-bottom: 15px;
  position: relative;
  align-items: center;

  &::after {
    content: "";
    position: absolute;
    top: -7px;
    left: 110px;
    transform: translateX(-50%);
    width: 100%;
    height: 1px;
    border-top: 1px dashed #000;
  }
}
.right_section_obj {
  display: flex;
  flex-grow: 1;
  width: 33.3%;
}

.left_section_obj {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  flex-grow: 1;
  width: 33.3%;
  padding-left: 5px;
}

.bp_name_object {
  width: 75px;
  line-height: 20px;
  flex-shrink: 0;
}

.center_block {
  display: flex;
  box-sizing: border-box;
  flex-grow: 1;
  width: 33.3%;
}

.type_treaty {
  font-family: "Calibri Italic", "Calibri Regular", "Calibri";
  font-weight: 400;
  font-size: 16px;
  font-style: italic;
  text-decoration: none;
  color: #aaaaaa;
}

.bp_name_obj {
  font-family: "Calibri Italic", "Calibri Regular", "Calibri";
  font-weight: 400;
  font-size: 16px;
  font-style: italic;
  text-decoration: none;
  color: #aaaaaa;
}

.responsible_name {
  height: 55px;
  width: 120px;
}

@media (max-width: 1700px) {
  .completed_tasks_obj {
    display: flex;
    padding: 0;
    flex-shrink: 0;
  }
  .overdue_task_obj {
    display: flex;
  }
  .day_section {
    
  }
  .name_obj {
    
  }
  .tasks_in_work {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
  }
  .responsible_task {
    width: 165px;
    padding-right: 10px;
  }
  .treaty_obj {
    width: 145px;
    flex-shrink: 0;
  }
  .bp_name_object {
    width: 70px;
    flex-shrink: 0;
  }
  .status_object {
    width: 100px !important;
  }

  .responsible_name {
    height: 60px;
  }
}
