@import '../../../../../../../sass/variables';

.array-typical-task-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 10px;
  align-items: center;
  
  &__value {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__actions {
    flex-shrink: 0;
  }

  &__btn {
    background: none;
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;
    
    &:hover {
      &.array-typical-task-item__btn--delete {
        color: $red-main;
      }
    }
    
    &--delete {
      > svg {
        transform: scale(0.9);
      }
    }
  }
}
