@import '../../../../../../sass/mixins';
@import '../../../../../../sass/variables';

.array-typical-task {
  &__title {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 7px;
  }

  &__table {
    
  }

  &__table-header {
    background: #01b0e9;
    border-radius: 4px;
    color: #fff;
    padding: 15px 10px;
    font-size: 13px;
    font-weight: 500;
  }

  &__table-rows {
    overflow-y: scroll;
    max-height: 181px;
  }
 
  &__table-row {
    border-bottom: 1px solid $border-gray;
  }

  &__add-wr {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  &__add-left {
    flex-grow: 1;
  }

  &__add-right {
    flex-shrink: 0;
    margin-left: 20px;
  }

  &__input {
    @include placeholder {
      font-size: 14px;
    }
    
    border: 1px solid $border-gray;
    border-radius: 7px;
    width: 100%;
    padding: 13px 10px 13px 15px;
    font-size: 14px;
    transition: border-color 0.25s ease-in-out;

    &:hover {
      border-color: $hoverColorBlue;
    }

    &:focus-within {
      border-color: $hoverColorBlue;
    }

    &--error {
      border-color: $red-main;
    }
  }

  &__input-btn {
    border-radius: 7px;
    transition: all 0.25s ease-in-out;
    font-family: Roboto,sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    background: #06A0EB;
    font-weight: 500;
    color: #fff;
    padding: 14px 20px;
    
    &:hover {
      background: #02405e;
    }
  }

  &__table-no-values {
    margin-top: 10px;
    padding: 0 10px;
    font-size: 14px;
  }

  &__error-message {
    margin-top: 5px;
    color: $red-main;
    font-size: 12px;
  }
}
