@import '../../../../../../sass/variables';

.modal-check-add-task {
  display: flex;
  align-items: center;
  justify-content: center;
  
  &__content-wr {
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: $black-darkest;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    padding: 16px; 
  }

  &__content-item {
    margin-bottom: 40px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title-wr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__title {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  
  &__delete-ico-wr {
    font-size: 24px;
    font-weight: 700;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    
    &:hover {
      color: $red-darker;
    }
  }
  
  &__text {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #212121;
    padding: 0 0;
  }
  
  &__btns {
    display: flex;
  }
  
  &__btn {
    display: flex;
    align-items: center;
    color: #fff;
    margin-right: 25px;
    transition: all 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid transparent;
    font-family: Roboto,sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    background: #06A0EB;
    font-weight: 500;
    
    &:last-child {
      margin-right: 0;
    }
    
    &--delete {
      &:hover {
        background: #02405e;
      }
    }
    
    &--cancel {
      border: 1px solid #e0e0e0;
      font-weight: normal;
      text-align: center;
      background: #e0e0e0;
      color: #212121;
      
      &:hover {
        border-color: #A8A8A8;
      }
    }
    
    &--show-preloader {
      .modal-check-add-task__btn-preloader {
        opacity: 1;
        visibility: visible;
        z-index: 10;
      }
    }
  }
  
  &__btn-title {
    font-weight: 500;
    padding: 8px 45px;
  }
  
  &__btn-preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02405e;

    .preloader-local {
      top: 0;
      width: 18px;
      height: 18px;

      > div {
        width: 18px;
        height: 18px;
        border-width: 2px;
      }
    }
  }
}
