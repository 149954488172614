@import '../../../../sass/mixins';
@import '../../../../sass/variables';

.form-add-task {
  //@include backgroundCustom;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  will-change: opacity, visibility;
  opacity: 0;
  visibility: hidden;

  &--open {
    opacity: 1;
    visibility: visible;

    .form-add-task__content-wr {
      transform: translateX(calc(100% - 1200px));
    }
  }

  &__content-wr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.25s ease-in-out;
    will-change: translateX;
    transform: translateX(100%);
  }
  
  &__content {
    position: relative;
    width: 600px;
    background: $formBg;
    transform: translateX(100%);
    padding: 30px;
    border-left: 10px solid $blue-lighter;
    border-right: 10px solid $blue-lighter;
    height: calc(100vh - 63px);
    overflow-y: scroll;
  }

  &__title-wr {
    display: flex;
    align-items: flex-start;
    margin-bottom: 23px;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    //overflow: hidden;
    //text-overflow: ellipsis;
  }

  &__title-text {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 30px;
  }

  &__title-chk {
    .MuiIconButton-label {
      transform: scale(0.9);  
    }
    
    .MuiFormControlLabel-label {
      font-size: 14px !important;
    }
  }

  &__close {
    background: none;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: $red-main;
    }
  }

  &__blocks {
    
  }

  &__block {
    margin-bottom: 20px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__block-title {
    font-size: 16px;
    font-weight: 500;
  }

  &__block-items {
    margin-top: 10px;
  }

  &__block-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__block-item-left {
    flex-shrink: 0;
    min-width: 150px;
  }

  &__block-item-right {
    flex-grow: 1;
  }

  &__block-item-title {
    
  }

  &__block-item-select {
    
  }

  &__block-item-chk-wr {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  &__block-item-chk {
    .MuiIconButton-label {
      transform: scale(0.9);
    }

    .MuiFormControlLabel-label {
      font-size: 14px !important;
    }
  }

  &__block-item-info {
    margin-left: 20px;
    flex-shrink: 0;
  }

  &__block-item-select-wr {
    display: flex;
    align-items: center;
  }
  
  &__block-item-select {
    flex-grow: 1;
    
    .multi-select-vanilla__title-wr {
      height: 40px;
    }
    
    .multi-select-vanilla__title {
      font-size: 14px;
    }
    
    .multi-select-vanilla__title-ico {
      transform: scale(0.8);
    }
    
    .multi-select__search-input {
      font-size: 14px;
    }
  }

  &__block-item-file-add-btn-wr {

  }

  &__block-item-file-add-btn {
    padding: 11px 10px 10px 15px;
    background: #fff;
    font-size: 14px;
    width: 100%;
    border: 1px solid $border-gray;
    border-radius: 7px;
    transition: border-color 0.25s ease-in-out;
    font-weight: 500;
    position: relative;
    max-width: 170px;

    &:hover {
      border-color: $hoverColorBlue;
    }
  }

  &__block-item-file-add-btn-input {
    display: block !important;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 0;
  }

  &__block-item-file-add-btn-title {
    font-weight: 400;
  }

  &__block-item-file-info {
    margin-top: 10px;
  }

  &__full-form {
    margin-top: 20px;
  }

  &__full-form-btn {
    display: flex;
    align-items: center;
    background: none;
    transition: all 0.25s ease-in-out;
    text-decoration: underline;
    
    &:hover {
      color: $hoverColorBlue;
    }
  }

  &__full-form-btn-ico {
    color: #7f7f7f;
  }

  &__full-form-btn-text {
    margin-left: 10px;
  }

  &__full-form-content {
    margin-top: 20px;
    font-size: 13px;
    position: relative;
    border-left: 10px solid rgba(215, 215, 215, 1);
  }

  &__full-form-content-disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }
  
  &__btns {
    display: flex;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-right: 25px;
    transition: all 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid transparent;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    background: #06a0eb;
    height: 40px;
    margin-top: 50px;

    &:last-child {
      margin-right: 0;
    }

    &--submit {
      width: 180px;

      &:hover {
        background: #02405e;
      }
    }

    &--cancel {
      border: 1px solid #e0e0e0;
      text-align: center;
      background: #e0e0e0;
      color: #212121;
      width: 120px;

      &:hover {
        border-color: #a8a8a8;
      }
    }

    &--show-preloader {
      .form-add-task__btn-preloader {
        opacity: 1;
        visibility: visible;
        z-index: 10;
      }
    }
  }

  &__btn-preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02405e;

    .preloader-local {
      top: 0;
      width: 18px;
      height: 18px;

      > div {
        width: 18px;
        height: 18px;
        border-width: 2px;
      }
    }
  }
}
