@import '../../../../../../sass/variables';

.mobile-regulations {
  position: absolute;
  top: 50%;
  transform: translateY(-69px);
  transition: all 0.25s ease-in-out;
  opacity: 0;
  visibility: hidden;
  padding: 15px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 200px;
  left: 72px;
  z-index: 1000;
  
  &__items {
    
  }

  &__item {
    padding: 5px;
    margin-bottom: 5px !important;
    transition: all 0.25s ease-in-out;
    
    &:hover {
      color: $hoverColorBlue;
    }
    
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
