@import "../../../../sass/variables.sass";

.react-select {
  background-color: #fff;
  transition: border-color 0.25s ease-in-out;
  
  .react-select__menu {
    background-color: #fff !important;
  }
  
  .react-select__option {
    background-color: #fff;
    color: black;
    transition: background-color 0.25s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: #b3d4fc;
    }
  }
  .react-select__control {
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-shadow: none;
    transition: all 0.2s;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    cursor: pointer;

    &:hover {
      border-color: $hoverColorBlue;
    }
  }
  .react-select__control--is-focused {
    border-color: $hoverColorBlue;
  }
  
  &__clear-indicator {
    transition: color 0.25s ease-in-out !important;
    
    &:hover {
      color: $red-main !important;
    }
  }
}

.block_checkbox {
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 40px;
  color: #02405e;
  
  &:hover {
    .checkbox-custom__box {
      border-width: 2px;
      border-color: $hoverColorBlue;
    }
    
    .block_checkbox__title {
      color: $blue-main;
    }
  }
  
  .checkbox-custom__box {
    border-width: 2px;
    transition: all 0.25s ease-in-out;
  }
  
  &__title {
    transition: color 0.25s ease-in-out;
    margin-top: 1px;
  }
  
  &__tooltip {
    margin-left: 15px;
  }
}

.executors-select {
  width: 100%;
  
  &__wr {
    position: relative;
  }

  &__preloader {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 6px;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 90;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &--show {
      opacity: 1;
      visibility: visible;
    }

    .preloader-local {
      opacity: $preloaderSlowReqOpacity;

      > div {
        border-color: $blue-main transparent transparent transparent;
      }
    }
  }
}
