@import "../../../../sass/variables";

.tasks_table_header_tasks {
  width: 100%;
  height: 60px;
  padding: 0 30px 0 20px;
  display: flex;
  justify-content: space-between;
}

.block_table_head_tasks {
  display: flex;
  justify-content: space-between;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #a8a8a8;
  align-items: center;
  height: 60px;

  svg {
    width: 10px;
    height: 10px;
  }
}

.header_items_tasks{
  position: relative;
}

.header_item_tasks {
  padding-left: 4px;
  transition: all 0.25s ease-in-out;
  
  &:hover {
    color: $hoverColorBlue;
  }
  
  & > span {
    position: relative;
  }
  &__id {
    min-width: 105px;
    text-align: center;
  }
 &__name {
    width: 85%;
    padding-left: 22px;
  }

  &__author_id {
    width: 190px;
  }

  &__executor_id {
    width: 190px;
  }

  &__status_id {
    width: 161px;
  }

  &__end {
    width: 93px;
  }

  &__project_name {
    width: 155px;
  }

  &__priority_id {
    width: 136px;
  }
  @media (max-width: 1550px){
    &__author_id {
      width: 150px;
    }
  
    &__executor_id {
      width: 150px;
    }
  }
}

.text_header {
  cursor: pointer;
}
