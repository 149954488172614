.personal-toggle {
    display: flex;
    align-items: center;
    color: #707070;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.04);
    background: #f2f2f2;
    border: 1px solid #f2f2f2;
    border-radius: 6px;
    height: 36px;
    padding: 2px 3px;
    font-size: 14px;
  
    &__item {
      cursor: pointer;
      border-radius: 6px;
      padding: 8px 15px;
      transition: all 0.25s ease-in-out;
  
      &:hover {
        color: #01B0E9;
      }
  
      &--active {
        background: #FFFFFF;
        color: #01B0E9;
        pointer-events: none;
      }
    }
  }
  