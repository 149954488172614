@import '../../../../sass/variables';

.tooltip-question {
  cursor: pointer;
  border: 1px solid $black-lighter;
  border-radius: 50%;
  transition: all 0.25s ease-in-out;
  width: 16px;
  height: 16px;
  text-align: center;
  font-size: 12px;
  position: relative;
  
  &:hover {
    color: $hoverColorBlue;
    border-color: $hoverColorBlue;
  }
  
  &__content {
    position: absolute;
    top: 2px;
    left: 4px;
  }
}
