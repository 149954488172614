@import '../../../../../sass/variables';

.extra-form {
  position: relative;
  width: 100%;
  padding: 80px 20px 20px 20px;

  @media #{$desktop1600W} {
    height: 100%;
  }

  &__content {
    position: relative;
    z-index: 100; 
  }
  
  &__default {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    background: $formBg;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    will-change: opacity, visibility;
    padding: 75px 30px 75px 30px;
    line-height: 1.45;

    @media #{$desktop1600W} {
      padding: 75px 200px 75px 30px;
    }

    &--open {
      opacity: 1;
      visibility: visible;
    }
  }

  &__default-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__default-text {
    margin-left: 20px;
    font-size: 13px;
  }

  &__default-text-item {
    list-style-type: disc;
    margin-bottom: 15px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title-wr {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  &__title {
    margin-right: 20px;
    font-weight: 600;
    font-size: 16px;
  }

  &__tooltip {
    position: relative;
    top: -1px;
  }

  &__tabs {
    margin-bottom: 30px;
    
    .MuiPaper-root {
      background-color: transparent;
      color: $black-darkest;
      box-shadow: none;
      position: relative;
      margin-bottom: 30px;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: $black-lighter;
      }
    }
  
    .MuiTabs-flexContainer {
      
    }
    
    .MuiButtonBase-root {
      flex-grow: 1;
      min-width: auto;
      text-transform: none;
      font-weight: 400;
      font-size: 14px;
      opacity: 1;
      transition: color 0.25s ease-in-out;
      
      &:hover {
        color: $hoverColorBlue;
      }
    }
    
    .Mui-selected {
      color: $hoverColorBlue;
    }
    
    .MuiTabs-indicator {
      z-index: 10;
      background-color: $hoverColorBlue !important;
    }
  }
  
  &__tabs-header {
    
  }
  
  &__parameters {
    
  }

  &__parameters-title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 15px;
  }

  &__parameters-items {
    margin-bottom: 30px;
  }

  &__parameters-item {
    margin-bottom: 10px;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    .MuiFormControlLabel-label {
      font-size: 15px !important;
    } 
  }
  
  &__btns {
    display: flex;
  }
  
  &__btn {
    display: flex;
    align-items: center;
    color: #fff;
    margin-right: 25px;
    transition: all 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid transparent;
    font-family: Roboto,sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    background: #06A0EB;
    font-weight: 500;

    &:last-child {
      margin-right: 0;
    }

    &--submit {
      &:hover {
        background: #02405e;
      }
    }

    &--cancel {
      border: 1px solid #e0e0e0;
      font-weight: normal;
      text-align: center;
      background: #e0e0e0;
      color: #212121;

      &:hover {
        border-color: #A8A8A8;
      }
    }
  }

  &__btn-title {
    font-weight: 500;
    padding: 8px 45px;
  }
}
