@import '../../../../sass/mixins';
@import '../../../../sass/variables';

.form-edit-task-objects-page {
  //@include backgroundCustom;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out, transform 0.25s ease-in-out;
  will-change: opacity, visibility, transform;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);

  &--open {
    opacity: 1;
    visibility: visible;

    .form-edit-task-objects-page__content {
      transform: translateX(calc(100% - 1200px));
    }
  }

  &--show-preloader {
    .form-edit-task-objects-page__preloader {
      opacity: 1;
      visibility: visible;
    }

    .form-edit-task-objects-page__content {
      overflow-y: hidden;
    }
  }

  &__preloader {
    @include backgroundCustom;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    will-change: opacity, visibility;
    opacity: 0;
    visibility: hidden;
    z-index: 2000;
  }

  &__tabs {
    margin-top: 20px;
    margin-bottom: 30px;

    .MuiPaper-root {
      background-color: transparent;
      color: $black-darkest;
      box-shadow: none;
      position: relative;
      margin-bottom: 30px;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: $black-lighter;
      }
    }

    .MuiTabs-flexContainer {

    }

    .MuiButtonBase-root {
      flex-grow: 1;
      min-width: auto;
      text-transform: none;
      font-weight: 400;
      font-size: 14px;
      opacity: 1;
      transition: color 0.25s ease-in-out;

      &:hover {
        color: $hoverColorBlue;
      }
    }

    .Mui-selected {
      color: $hoverColorBlue;
    }

    .MuiTabs-indicator {
      z-index: 10;
      background-color: $hoverColorBlue !important;
    }

    .Mui-disabled {
      cursor: not-allowed !important;
      pointer-events: all !important;

      &.MuiButtonBase-root {
        &:hover {
          color: inherit !important;
        }
      }
    }
  }

  &__tab-content {

  }

  &__content {
    position: relative;
    width: 600px;
    background: $formBg;
    transition: transform 0.25s ease-in-out;
    will-change: translateX;
    padding: 30px;
    border-left: 10px solid $blue-lighter;
    border-right: 10px solid $blue-lighter;
    height: calc(100vh - 63px);
    overflow-y: scroll;
  }

  &__title-wr {
    display: flex;
    align-items: flex-start;
    margin-bottom: 23px;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    //overflow: hidden;
    //text-overflow: ellipsis;
  }

  &__title-text {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 30px;
  }

  &__title-chk {
    .MuiIconButton-label {
      transform: scale(0.9);  
    }
    
    .MuiFormControlLabel-label {
      font-size: 14px !important;
    }
  }

  &__close {
    background: none;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: $red-main;
    }
  }

  &__blocks {
    
  }

  &__block {
    margin-bottom: 20px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__block-title {
    font-size: 16px;
    font-weight: 500;
  }

  &__block-items {
    margin-top: 10px;
  }

  &__block-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__block-item-left {
    flex-shrink: 0;
    min-width: 150px;
  }

  &__block-item-right {
    flex-grow: 1;
  }

  &__block-item-title {
    
  }

  &__block-item-select {
    
  }

  &__block-item-chk-wr {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  &__block-item-chk {
    .MuiIconButton-label {
      transform: scale(0.9);
    }

    .MuiFormControlLabel-label {
      font-size: 14px !important;
    }
  }

  &__block-item-info {
    margin-left: 20px;
    flex-shrink: 0;
  }

  &__block-item-select-wr {
    display: flex;
    align-items: center;
  }
  
  &__block-item-select {
    flex-grow: 1;
    
    .multi-select-vanilla__title-wr {
      height: 40px;
    }
    
    .multi-select-vanilla__title {
      font-size: 14px;
    }
    
    .multi-select-vanilla__title-ico {
      transform: scale(0.8);
    }
    
    .multi-select__search-input {
      font-size: 14px;
    }
  }

  &__block-item-file-add-btn-wr {

  }
  
  &__btns {
    display: flex;
    margin-top: 30px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-right: 25px;
    transition: all 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid transparent;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    background: #06a0eb;
    height: 40px;

    &:last-child {
      margin-right: 0;
    }

    &--submit {
      width: 180px;

      &:hover {
        background: #02405e;
      }
    }

    &--cancel {
      border: 1px solid #e0e0e0;
      text-align: center;
      background: #e0e0e0;
      color: #212121;
      width: 120px;

      &:hover {
        border-color: #a8a8a8;
      }
    }

    &--show-preloader {
      .form-edit-task-objects-page__btn-preloader {
        opacity: 1;
        visibility: visible;
        z-index: 10;
      }
    }
    
    &[disabled] {
      pointer-events: none;
    }
  }

  &__btn-preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #02405e;

    .preloader-local {
      top: 0;
      width: 18px;
      height: 18px;

      > div {
        width: 18px;
        height: 18px;
        border-width: 2px;
      }
    }
  }
}
