$blue-main: #1BAAF0;
$blue-lighter: #99D7F5;
$blue-lightest: #F3FAFE;
$blue-dark: #E6ECEF;
$blue-darker: #02405E;
$black-lightest: #F2F2F2;
$black-white: #FFFFFF;

$light_accent: #54C7FF;
$black-lighter: #E0E0E0;
$black-middle: #A8A8A8;
$black-darker: #707070;
$black-darkest: #212121;
$yellow-main: #FFDE89;
$yellow-lighter: #FFFDF7;
$yellow-darker: #B9932F;
$green-main: #27CBA6;
$green-darker: #008C6D;
$green-lighter: #F2FEFC;
$red-main: #FF6666;
$red-lighter: #FFF2F2;
$red-darker: #CC0000;
$purple-main: #BB6BD9;
$purple-figma: #7B61FF;
$purple-darker: #B201F1;
$purple-lightest: #FBF3FE;

$border-gray: #D8D8D8;

$black: rgba(0, 0, 0, 0.87);
$grey_border: 1px solid rgba(0, 0, 0, 0.12);
$blue_background: rgba(27, 170, 240, 0.1);
$disabled: rgba(0, 0, 0, 0.02);
$disabled_text: #2021249f;

$hoverColorBlue: #1BAAF0;
$formBg: #F9F9F9;

$taskBpChildOffset: 50px;
$taskBpHeight: 44px;

$mobilePoints: 320px 370px 410px;
$mobileLandPoints: 480px 560px 620px;
//                   1      2     3
$tabletPoints: 767px 960px;
$desktopPoints: 1280px 1366px 1600px 1900px;
$mac: 2000px;

$mobile370W: 'screen and (min-width: #{nth($mobilePoints, 1)}';
$mobileLand480W: 'screen and (min-width: #{nth($mobileLandPoints, 1)})';
$mobile767W: 'screen and (min-width: #{nth($tabletPoints, 1)}';
$tablet767W600H: 'screen and (min-width: #{nth($tabletPoints, 1)} and (min-height: 600px)';
$tabletLand960W: 'screen and (min-width: #{nth($tabletPoints, 2)}';
$desktop1200W700H: 'screen and (min-width: #{nth($desktopPoints, 1)} and (max-height: 700px)'; // 1200W и высота меньше 700H
$desktop1260W: 'screen and (min-width: #{nth($desktopPoints, 1)}';
$desktop1366W: 'screen and (min-width: #{nth($desktopPoints, 2)})';
$desktop1600W: 'screen and (min-width: #{nth($desktopPoints, 3)})';
$desktop1900W: 'screen and (min-width: #{nth($desktopPoints, 4)})';
$mac2000W: 'screen and (min-width: #{$mac})';

//@media #{$desktop1600W} {

//}
$preloaderSlowReqOpacity: 0.6;
