@import "../../../../../../../sass/variables.sass";

.extra-form-typical-bp {
  padding: 80px 20px 20px;
  height: 100%;
  overflow-y: scroll;

  &__table-header {
    width: 98%;
    background-color: $blue-main;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    display: flex;
    font-size: 13px;
    font-weight: 400;
    height: 40px;
    margin-bottom: 10px;
    padding: 10px;
    &__id {
      width: 15%;
    }
    &__name {
      width: 55%;
    }
  }

  &__content-typical-tasks {
    position: relative;

    &__inner {
        overflow-y: scroll;
        max-height: 60vh;
        height: 100%;
    }

    &__preloader-wr {
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.25s ease-in-out;
        will-change: opacity, visibility;
        opacity: 1;
        // visibility: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;
        backdrop-filter: blur(1px);
        background: rgba(255, 255, 255, 0.9);
        pointer-events: none;

        &--show {
          opacity: 1;
          visibility: visible;
        }
      
        .preloader-local {
          width: 42px;
          height: 42px;
          transform: transition(0);
      
          > div {
            border-color: $blue-main transparent transparent transparent;
            width: 42px;
            height: 42px;
            border-width: 3px;
          }
        }
      }
  }

  &__content {
    position: relative;
    z-index: 100;

    &__typical-task {
      display: flex;
      width: 98%;
      align-items: center;
      background: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-bottom: 10px;
      min-height: 44px;
      padding: 0 10px;
      &__id {
        width: 15%;
      }
      &__name {
        width: 55%;
      }
      &__term {
        width: 15%;
        padding-left: 5px;
      }
      &__add {
        background: none;
        &:hover {
          text-decoration: underline;
          color: $blue-main;
        }
      }
    }
  }
}

