@import "../../../../../../sass/variables.sass";

.typical-task-bp {
  width: 100%;
  margin-bottom: 20px;
  cursor: grab;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: $taskBpHeight;
  transition: border-color 0.25s ease-in-out;

  &:first-child {
    &.typical-task-bp--child {
      .typical-task-bp__connect-wr {
        //display: block;
      }
    }
  }
  
  &:last-child {
    margin-bottom: 0;
    
    // Отображение первой связи родитель-ребенок для последнего дочернего элемента
    &.typical-task-bp--child {
      .typical-task-bp__connect-wr {
        display: block;
      }
    }

    .typical-task-bp__connect-wr {
      display: none !important;
    }
  }
  
  &.react-draggable-dragging {
    z-index: 100;
    position: relative;
    cursor: grabbing;
  }
  
  &--parent {
    // Отображение первой связи родитель-ребенок
    + .typical-task-bp--child {
      .typical-task-bp__connect--parent-child-parallel {
        display: flex; 
      }
    }
  }
  
  &--child {
    width: calc(100% - #{$taskBpChildOffset});
  }
  
  &--last-child {
    .typical-task-bp__connect-wr {
      display: none;
    }
  }
  
  &--active {
    border-color: $blue-main;
  }
  
  &--serial {
    .typical-task-bp__connect--serial {
      display: block;
    }
    
    .typical-task-bp__connect--parallel {
      display: none;
    }

    .typical-task-bp__connect--parent-child-parallel {
      display: none;
    }
  }
  
  &--parallel {
    .typical-task-bp__connect--parallel {
      display: flex;
    }
    
    .typical-task-bp__connect--serial {
      display: none;
    }

    .typical-task-bp__connect--parent-child-parallel {
      display: none;
    }
  }

  &--one-child {
    &.typical-task-bp--child {
      .typical-task-bp__connect-wr {
        display: block !important;
      }
    }
    
    .typical-task-bp__connect--serial {
      display: none;
    }

    .typical-task-bp__connect--parallel {
      display: none;
    }
  }
  
  &--show-position-parent {
    .typical-task-bp__position {
      opacity: 1;
      visibility: visible;
    }
  }
  
  &--show-position-child {
    .typical-task-bp__position {
      opacity: 1;
      visibility: visible;
    }

    .typical-task-bp__position-line {
      width: calc(100% - #{$taskBpChildOffset});
    }
  }

  &--parent-has-child {
    .typical-task-bp__connect-break {
      display: none;
    }
    
    .typical-task-bp__content-btn--break {
      display: flex;
    }
  }
  
  /**/

  &__position {
    position: absolute;
    top: -3px;
    left: 0;
    width: 100%;
    height: 3px;
    opacity: 0;
    visibility: hidden;
    display: flex;
    justify-content: flex-end;
    transition: all 0.25s ease-in-out;
  }

  &__position-line {
    width: 100%;
    height: 100%;
    background: $blue-main;
    transition: width 0.25s ease-in-out;
  }
  
  &__connect-wr {
    position: absolute;
    top: $taskBpHeight; // Сдвиг на высоту элемента задачи
    left: 20px;
    height: 100%;
  }

  &__connect {
    display: flex;
    height: 100%;
    position: relative;
    
    > div {
      height: 100%;
      width: 1px;
      margin-right: 20px;
      border-left: 1px dashed $blue-main;
      
      &:last-child {
        margin-right: 0;
      }
    }

    &--serial {
      display: none;
    }

    &--parallel {
      display: none;
    }

    &--parent-child-parallel {
      margin-right: 70px;
      position: absolute;
      bottom: 87px;
      height: 19px;
    }
  }

  &__connect-break {
    position: absolute;
    top: -3px;
    left: 0;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7f7f7f;
    width: 24px;
    height: 24px;

    &:hover {
      > svg {
        color: $red-main;
      }
    }

    > svg {
      transition: all 0.25s ease-in-out;
      transform: scale(0.8);
    }
  }
  
  &__content {
    display: grid;
    grid-template-columns: 50px 1fr 130px 50px;
    padding: 0 10px;
    align-items: center;
    
    width: 100%;
    height: 100%;
  }

  &__content-col {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
    &:last-child {
      display: flex;
      justify-content: center;
    }
  }

  &__content-btn {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7f7f7f;
    width: 24px;
    height: 24px;
    
    &:hover {
      > svg {
        color: $red-main;
      }
    }
    
    > svg {
      transition: all 0.25s ease-in-out;
      transform: scale(0.8);
    }
    
    &--close {
      position: relative;
      top: 1px;
    }
    
    &--break {
      display: none;
    }
  }
}
