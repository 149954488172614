@import "../../../../../../sass/variables.sass";

.accordion_section {
  transition: box-shadow 0.25s ease-in-out !important;
}

.object_section {
  &__actions {
    padding: 10px 15px;
    position: absolute;
    top: 50%;
    right: -5px;
    transform: translateY(-50%);
  }

  &__actions-btn {
    color: #94959a;
    width: 25px;
    background: none;
    transition: all 0.25s ease-in-out;
    height: 100%;
  }

  &__actions-btn:hover {
    color: $hoverColorBlue;
  }

  &__no-tasks {
    font-size: 14px;
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    margin: 0 20px 10px 20px;
  }
}

.left_section {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text_section {
  box-sizing: border-box;
  display: inline;
  color: #292a34;
  line-height: 24px;
  text-decoration: none;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.right_section {
  display: flex;
  align-items: center;
  width: 265px;

  @media #{$desktop1600W} {
    width: 297px;
  }

  @media #{$desktop1900W} {
    width: 370px;
  }

  @media #{$mac2000W} {
    width: 570px;
  }
}

.complet_task {
  width: 125px;
  display: flex;

  @media #{$desktop1600W} {
    width: 211px;
  }
  
  @media #{$desktop1900W} {
    width: 149px;
  }

  @media #{$mac2000W} {
    width: 134px;
  }
}

.overdue_task {
  width: 135px;
  display: flex;
}

.exec_task_obj {
  width: 230px;
  display: flex;
  padding: 5px;
  align-items: center;
}

.status_obj_section {
  width: 127px;
}
