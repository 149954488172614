@import "../../../../sass/variables";

.typical-tasks_table_header {
  width: 100%;
  height: 60px;
  padding: 0 120px 0 80px;

  .block_table_head {
    display: flex;
    justify-content: space-between;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #a8a8a8;
    align-items: center;
    height: 60px;
    
    svg {
      width: 10px;
      height: 10px;
    }
  }

  .header_item_name {
    padding-left: 4px;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: $hoverColorBlue;
    }

    & > span {
      position: relative;
    }
  }

  .text_header {
    cursor: pointer;
  }

  .header_items_typical_tasks{
    position: relative;
  }

  .header-item-typical-tasks {
    padding-left: 4px;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: $hoverColorBlue;
    }

    & > span {
      position: relative;
    }

    &__id {
      width: 45px;
    }

    &__name {
      min-width: 280px;
    }

    &__bp {
      width: 200px;
    }

    &__author {
      width: 200px;
    }

    &__executor {
      width: 200px;
    }

    &__createdAt {
      width: 200px;
    }

    &__activity {
      width: 97px;
    }
  }
}
