.execution-tab-objects-task-edit {
  font-size: 14px;
  position: relative;

  &--disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    backdrop-filter: grayscale(1);
  }

  &__content {
    
  }

  &__block-item {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__block-item-left {
    flex-shrink: 0;
    min-width: 150px;
  }

  &__block-item-right {
    flex-grow: 1;
  }

  &__block-item-title {

  }
  
  &__additional-block {
    margin-top: 20px;
  }
  
  &__additional-block-title {
    font-size: 16px;
    font-weight: 500;
  }
  
  &__additional-block-content {
    margin-top: 20px;
    position: relative;

    &--disabled {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      backdrop-filter: grayscale(1);
    }
  }

  &__additional-block-status {
    .taskStatusWrapper {
      justify-content: flex-start;
    }
  }
}
