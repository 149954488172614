@import "../../../../../sass/variables.sass";

.extra-form-bp {
  padding: 80px 20px 20px;
  height: 100%;
  overflow-y: scroll;

  &__default {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
    background: $formBg;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    will-change: opacity, visibility;
    padding: 75px 30px 75px 30px;
    line-height: 1.45;

    @media #{$desktop1600W} {
      padding: 75px 170px 75px 30px;
    }

    &--open {
      opacity: 1;
      visibility: visible;
    }
  }

  &__default-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__default-text {
    margin-left: 20px;
    font-size: 16px;
  }

  &__default-text-instruction {
    margin-top: 20px;
    font-size: 16px;
    &:hover {
      text-decoration: underline;
    }

  }

  &__instruction{
    display: flex;
    > svg {
      margin-top: 5px
    }
    &__text{
      margin-left: 20px;
    }
  }

  &__default-text-item {
    list-style-type: disc;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
    &__text{
      font-weight: 500;
    }
  }

  &__title-wr {
    display: flex;
  }
  &__filters {
    display: flex;
  }
  &__filter {
    width: 50%;
    padding: 20px 0;
  }
  &__name-filter {
    padding: 15px 0;
    text-wrap: nowrap;
  }
 

  &__bp-select {
    text-decoration: underline;
    &:hover{
        cursor: pointer;
        color: $blue-main;
    }
  }

  &__content {
    position: relative;
  }

  &__btns {
    display: flex;
    margin-top: 30px;
  }

  &__btn {
    display: flex;
    align-items: center;
    color: #fff;
    margin-right: 25px;
    transition: all 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid transparent;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    background: #06a0eb;
    font-weight: 500;

    &:last-child {
      margin-right: 0;
    }

    &--submit {
      &:hover {
        background: #02405e;
      }
    }

    &--cancel {
      border: 1px solid #e0e0e0;
      font-weight: normal;
      text-align: center;
      background: #e0e0e0;
      color: #212121;

      &:hover {
        border-color: #a8a8a8;
      }
    }
  }
  &__btn-title {
    font-weight: 500;
    padding: 8px 45px;
  }
}

.block_checkbox-bp {
  &__tooltip {
    width: 20px;
  }
}

.search-typical-task-bp {
  width: 80%;
  height: 50px;
  background-color: #fff;
  border-radius: 5px;
  padding: 12px;
  color: #212121;
  border: 1px solid rgb(153, 153, 153);
  resize: none;
  margin-bottom: 20px;

  &:hover {
    border-color: $hoverColorBlue;
  }

  &:focus-within {
    border-color: $hoverColorBlue;
  }
}
