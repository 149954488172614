.tasks_table_head_objects_task {
  overflow: hidden;
  width: 100%;
  padding: 0px 5px 0px 40px;
  margin-right: 8px;
  display: flex;
  height: 69px;
  justify-content: space-between;
}

.block_head-objects-task {
  display: flex;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #a8a8a8;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
  }
  &__right{
    margin-right: 12px;
    display: flex;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #a8a8a8;
    align-items: center;
  }
}

.header_item_objects_task {
  cursor: pointer;
  padding-left: 4px;
  & > span {
    position: relative;
  }
}
