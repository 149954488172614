@import '../../../../sass/variables';

// TODO возможно, где-то эти стили ещё определяются. Надо проверить
.react-select {
  &--error {
    .react-select__control {
      border-color: $red-main !important;
    }
  }
  
  &__control {
    transition: border-color 0.25s ease-in-out;
  }
  
  &__single-value {
    font-size: 14px;
  }

  &__value-container {
    padding: 6px 8px !important;
  }
  
  &__menu {
    position: relative;
    z-index: 1000;
  }
  
  &__option {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
