@import "../../../../../../../sass/variables.sass";

.accordion_treaty {
  width: 100%;
  align-items: center;
position: relative;
  &__vector {
    width: 15px;
    min-width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    transition: transform 0.3s ease;

    &.open {
      transform: rotate(90deg);
    }
  }
  &__name {
    width: 32%;
    margin-left: 10px;

    &:hover {
      color: $hoverColorBlue;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  &__count_bps {
    width: 15%;
  }
  &__count_tasks {
    width: 15%;
  }
  &__durartion {
    width: 15%;
  }
  &__actual {
    width: 18%;
  }
}

.react-select__indicator-separator {
  opacity: 0;
}

.MuiAccordionSummary-content {
  align-items: center;
}

.stage-treaty {
  display: flex;
  width: 100%;
  align-items: center;

  &__add-items{
    margin: 30px 0 0 30px;

    &__item{
      text-decoration: underline;
      padding-left: 5px;
      &:hover {
        color: $hoverColorBlue;
        cursor: pointer;
      }
    }
  }

 &__content{
  width: 100%;
 }
}

.stage-treaty__burger {
  margin-right: 10px;
  cursor: grab;

  > svg {
    transition: all 0.25s ease-in-out;
  }
  
  &:hover {
    color: $hoverColorBlue;
  }

  &:active {
    cursor: grabbing;
  }
}

.stages_items_none{
  text-align: center;
}