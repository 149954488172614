@import '../../../../../../sass/variables';

.description-tab {
  font-size: 14px;
  position: relative;
  
  &--disabled {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    backdrop-filter: grayscale(1);
  }
  
  &__info {
    &--margin {
      margin-top: 25px;
    }
    
    &--style {
      font-style: italic;
      color: #7F7F7F;

      .description-tab__info-left {
        min-width: 170px;
        width: 170px;
      }
    }
  }

  &__info-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  &__info-row {
    display: flex;
  }

  &__info-left {
    margin-right: 20px;
    min-width: 120px;
  }

  &__info-right {
    
  }

  &__blocks {
    margin-top: 20px;
  }
  
  &__block {
    margin-bottom: 25px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__block-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  &__block-items {
    
  }

  &__block-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  
  &__block-item-left {
    flex-shrink: 0;
    min-width: 170px;
    line-height: 1.3;
  }

  &__block-item-right {
    margin-left: 15px;
    flex-grow: 1;
  }

  &__block-item-file {
   margin-bottom: 15px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__block-item-file-add-btn-wr {
    
  }

  &__block-item-file-add-btn {
    padding: 14px 10px 12px 15px;
    background: #fff;
    font-size: 14px;
    width: 100%;
    border: 1px solid $border-gray;
    border-radius: 7px;
    transition: border-color 0.25s ease-in-out;
    font-weight: 500;
    position: relative;
    max-width: 220px;
    
    &:hover {
      border-color: $hoverColorBlue;
    }
  }

  &__block-item-file-add-btn-input {
    display: block !important;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: transparent;
    opacity: 0;
  }

  &__block-item-select {
    .react-select__value-container {
      padding: 5px 8px;
    }
    
    .react-select__single-value {
      font-size: 14px;
    }
    
    .react-select__input {
      font-size: 14px;
    }
  }
  
  &__datepicker-wr {
    width: 190px;
  }

  &__block-no-docs {
    margin-top: 15px; 
    margin-bottom: 15px; 
  }
}
