@import '../../../../sass/variables';

.checkItem {
  
  .deleteButton {
    visibility: visible !important;
  }
  
  .checkbox_item {
    display: none;
  }
  
  .checkbox_icon {
    border: none;
    border-radius: 0;
    background-position: -1px -1px;
  }
}
