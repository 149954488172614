@import "../../../../../../../sass/variables.sass";

.task_item {
  display: flex;
  min-height: 48px;
  border-radius: 4px;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  &:hover {
    cursor: pointer;
    color: $hoverColorBlue;
  }

  &:active {
    cursor: grabbing;
  }

  &__before_task {
    display: flex;
    min-height: 48px;
    border-radius: 4px;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 20px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    position: relative;

    &:hover {
      color: $hoverColorBlue;
    }

    &:not(:last-child)::before {
      content: "";
      position: absolute;
      border-top: 1px dashed black;
      flex: 1;
      margin: 0 10px;
      transform: rotate(90deg);
      bottom: -11px;
      width: 20px;
    }
  }

  &__name {
    width: 66%;
    padding-left: 10px;

    &:hover {
      color: $hoverColorBlue;
    }
  }
}