@import '../../../../../../../../sass/variables';

// TODO вынести в отдельный компонент
.react-select {
  .react-select__control {
    transition: border-color 0.25s ease-in-out;
  }
  
  &--error {
    .react-select__control {
      border-color: $red-main !important;
    }
  }
}
