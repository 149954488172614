.switch-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px; }
.switch-list {
    width: 100%; }

.switch-list-item-titles-block {
    width: 100%;
    display: flex;
    justify-content: space-between; }
.switch-list-item-title {
    width: 100%;
    text-align: center; }
.switch-items-block {
    width: 100%;
    display: flex;
    height: 20px;
    border: 1px solid gray;
    border-radius: 15px; }
.switch-item {
    width: 100%;
    height: 10px; }
.switch-item-active {
    width: 100%;
    height: 15px;
    border-radius: 5px;

    background-color: green; }
.switch-container {
    margin: 10px 0 10px 0; }

.switch {
    display: flex;
    justify-content: space-between;
    background-color: lightgray;
    border-radius: 5px;

    &-label {
        display: flex;
        align-items: center;
        padding: 5px; }

    &-label.active {
        background-color: green;
        color: white; } }

.buttons-wrapper {
    display: flex;
    width: 50%;
    gap: 25px; }

.list {
    max-height: 180px;
    overflow-y: auto;
    margin: 20px 0 20px 0;
    border: 1px solid #c7c2c2;
    border-radius: 5px;
    padding: 10px; }
.list-item {
    margin-top: 10px; }
.list-item:first-child {
    margin-top: 0; }

.table-header {
    width: 100%;
    display: flex;
    justify-content: space-between; }
