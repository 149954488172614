@import '../../../../sass/variables';

.checkbox-mui {
  &__chk {
    padding: 0 !important;
    margin-right: 5px !important;
    margin-left: 9px !important;
  }
  
  .MuiFormControlLabel-label {
    position: relative;
    top: 1px;
    font-size: 16px;
  }
  
  .MuiSvgIcon-root {
    transition: all 0.25s ease-in-out;
  }

  .Mui-checked {
    color: $hoverColorBlue;

    + .MuiFormControlLabel-label {
      color: $hoverColorBlue;
    }
  }
  
  &__label {
    margin-right: 0 !important;
    transition: all 0.25s ease-in-out;
    
    &:hover {
      color: $hoverColorBlue;
      
      .checkbox-mui__chk {
        color: $hoverColorBlue;
      }
    }
  }
}
