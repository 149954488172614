@import "../../../../../../sass/variables.sass";

.task_content {
  display: flex;
  margin: 0 12px;
  margin-bottom: 7px;
  justify-content: space-between;
  min-height: 84px;
  padding: 15px 0 15px 20px;
  border-radius: 4px;
  border-image: linear-gradient(to right, #ffffff, #e0e0e0, #e0e0e0, #ffffff)
    45% 0%;
  background: #ffffff;
  position: relative;
  cursor: pointer;
  transition: background-color 200ms ease;
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
  }

  &__actions {
    padding: 10px 4px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  &__actions-btn {
    color: #94959a;
    width: 25px;
    background: none;
    transition: all 0.25s ease-in-out;
    height: 100%;
  }

  &__actions-btn:hover {
    color: $hoverColorBlue;
  }
}

.id_task {
  color: #292a34;
  font-size: 14px;
  margin-top: 6px;
  width: 45px;
  color: #a8a8a8;
  text-align: center;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-left: 40px;
}

.left_box {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: center;
}

.right_box {
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.task_name_objects {
  width: 400px;
}

.executor_objects_task {
  display: flex;
  width: 210px;
}

.executor-name-objects-task {
  display: flex;
  align-items: center;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
}

.task_status_objects {
  margin-right: 7px;
  display: flex;
  align-items: center;
  width: 222px;
  pointer-events: none;
}

.deadline_task_objects{
  width: 195px;
  display: flex;
}

.priority-task-objects{
  display: flex;
  width: 195px;
}