@import '../../../../sass/mixins';
@import '../../../../sass/variables';

.additional-block {
  margin-left: 20px;

  &--execution {
    margin-left: 0;
    
    .additional-block__full-form-item-list {
      .checkbox-mui__chk {
        transform: scale(0.8);
      }
      
      .MuiFormControlLabel-label {
        font-size: 14px;
      }
    }
  }
  
  &__full-form-item {
    margin-bottom: 20px;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__full-form-item-left {
    flex-shrink: 0;
    min-width: 190px;
  }

  &__full-form-item-title {
    max-width: 160px;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__full-form-item-right {
    flex-grow: 1;
  }

  &__full-form-item-list {
    margin-left: 12px;

    > li {
      list-style-type: auto;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__full-form-textarea {

  }

  &__full-form-radio {
    .MuiFormControlLabel-root {
      display: block;
      
      &:last-child {
        margin-right: 0;
      }
    }

    .MuiFormControlLabel-label {
      font-size: 14px !important;
    }

    .MuiRadio-root {
      transform: scale(0.9);
    }
    
    .MuiButtonBase-root {
      padding: 0px 9px;
    }
  }

  &__full-form-checkbox {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    
    .checkbox-mui {
      margin-right: 15px;
      
      &:last-child {
        margin-right: 0;
      }
    }

    .MuiFormControlLabel-label {
      font-size: 14px !important;
    }
  }

  &__full-form-file {

  }

  &__full-form-file-btn {
    background: none;
    text-decoration: underline;
    transition: all 0.25s ease-in-out;
    position: relative;

    &:hover {
      color: $hoverColorBlue;
    }
  }

  &__full-form-file-btn-file {
    display: block !important;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: transparent;
    opacity: 0;
  }

  &__checklist-progress {
    background: #fff;
    width: 100%;
    height: 3px;
    position: relative;
    margin: 10px 0;
  }

  &__checklist-progress-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    transition: all 0.25s ease-in-out;
    background: $blue-main;
  }
  
  &__file-info {
    margin-top: 10px;
  }

  &__error-message {
    margin-top: 5px;
    color: $red-main;
    font-size: 12px;
  }
}
