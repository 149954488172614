@import "../../../../../sass/mixins";
@import '../../../../../sass/variables';

.objects-file-item {
  display: flex;
  align-items: flex-start;

  &__left {
    margin-right: 15px;
  }

  &__right {
    display: flex;
  }

  &__info {
    line-height: 1.2;
    margin-bottom: 10px;

    > a {
      text-decoration: underline;
      transition: all 0.25s ease-in-out;
      max-width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        color: $hoverColorBlue;
      }
    }
  }

  &__ico {
    color: #7f7f7f;
  }

  &__delete {
    margin-left: 15px;
  }

  &__delete-btn {
    background: none;
    transition: all 0.25s ease-in-out;
    transform: scale(0.8) translateY(-5px);
    
    &:hover {
      color: $red-main;
    }
  }
}
