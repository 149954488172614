@import "../../../../../../../sass/variables.sass";

.accordion_item_stage {
  width: 100%;
  align-items: center;

  &__vector {
    width: 15px;
    min-width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    transition: transform 0.3s ease;

    &.open {
      transform: rotate(90deg);
    }
  }
  &__name {
    width: 46.5%;
    margin-left: 10px;

    &:hover {
      color: $hoverColorBlue;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  &__count_tasks {
    width: 15.5%;
  }
  &__durartion {
    width: 15%;
  }
  &__actual {
    width: 20%;
  }

  &__btn {
    background: none;
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;

    &:hover {
      &.additional-fields-item__btn--delete {
        color: $red-main;
      }
    }

    &--delete {
      > svg {
        transform: scale(0.9);
      }
    }
  }
}

.MuiAccordion-root::before {
  background-color: #FFF !important;
}

.item-treaty{
  margin-bottom: 15px;
}

.task_item_stage {
  display: flex;
  width: 100%;
  min-height: 48px;
  border-radius: 4px;
  align-items: center;
  padding: 0 16px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  &__name {
    width: 66%;
  }

  &__btn {
    background: none;
    transition: all 0.25s ease-in-out;
    display: flex;
    align-items: center;
    position: absolute;
    right: 25px;

    &:hover {
      &.additional-fields-item__btn--delete {
        color: $red-main;
      }
    }

    &--delete {
      > svg {
        transform: scale(0.9);
      }
    }
  }
}


.item-stage__content {
  display: flex;
  align-items: center;
}

.item-stage__burger {
  display: flex;
  width: 16px;
  margin-right: 10px;

  cursor: grab;

  > svg {
    transition: all 0.25s ease-in-out;
  }
  
  &:hover {
    color: $hoverColorBlue;
  }

  &:active {
    cursor: grabbing;
  }
}
