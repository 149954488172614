@import '../../../../sass/variables';

.checklist {
  
  .addNewRowText {
    color: $black-darkest !important;
    transition: all 0.25s ease-in-out;

    &:hover {
      color: $hoverColorBlue !important;
    }
  }
  
  .checkbox_item {
    margin-top: -5px !important;
  }
  
  .deleteButton {
    visibility: visible;
  }
}
